import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

class Header extends Component {
    render() {
        return (
            <nav class="navbar navbar-expand-sm bg-success navbar-dark">
                <ul class="navbar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Accueil</Link>
                    </li>
                </ul>
            </nav>
        );
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
