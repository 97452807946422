import {AJOUTER_ALERTE_SUCCES, SUPPRIMER_ALERTE_SUCCES} from "../actions/action-types";

const initialState = {
    message: ""
};

export default function AlertesReducer(state = initialState, action) {
    switch (action.type) {
        case AJOUTER_ALERTE_SUCCES:
            return {
                message: action.payload
            };

        case SUPPRIMER_ALERTE_SUCCES:
            return {
                message: undefined
            };

        default:
            return state;
    }
}
