import {AJOUTER_ALERTE_SUCCES, SUPPRIMER_ALERTE_SUCCES} from "./action-types";

export function ajouterSucces(message) {
    return function(dispatch) {
        dispatch({
            type: AJOUTER_ALERTE_SUCCES,
            payload: message
        });
    }
}

export function supprimerSucces() {
    return function(dispatch) {
        dispatch({
            type: SUPPRIMER_ALERTE_SUCCES
        });
    }
}
