import {Provider} from 'react-redux'
import ReactDOM from 'react-dom'
import React from 'react'
import {createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk"
import reducers from './reducers'
import App from './App';
import {BrowserRouter} from "react-router-dom";

const invariant = require("redux-immutable-state-invariant").default();
const createStoreWithMiddleware = applyMiddleware(invariant, thunk)(createStore);

const store = createStoreWithMiddleware(
    reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__());

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>,
    document.querySelector('#root'));


