import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import {GENERER_ATTESTATION} from "./action-types";

export const URL_API_PEOPLE_ATTESTATION = "https://j91mcai87h.execute-api.us-east-1.amazonaws.com/prod";

export function genererAttestation(utilisateur) {
    return function (dispatch) {
        dispatch({
            type: GENERER_ATTESTATION,
            payload: utilisateur
        });
    }
}

export function resetAttestation() {
    return function (dispatch) {
        dispatch({
            type: GENERER_ATTESTATION,
            payload: undefined
        })
    }
}

export function ajouterMetrique({nom, prenom}) {
    return function () {
        const data = {
            id: uuidv4(),
            nom,
            prenom,
            date: recupererDate()
        };
        const option = {
            method: "POST",
            url: `${URL_API_PEOPLE_ATTESTATION}/attestations`,
            data: data,
            header: {
                "Content-Type": "application/json"
            }
        };
        axios(option);
    }
}

function recupererDate() {
    let maintenant = new Date();
    return `${maintenant.getDate()}/${maintenant.getMonth() + 1}/${maintenant.getFullYear()} à ${maintenant.getHours()}:${maintenant.getMinutes()}`
}
