import {LISTE_UTILISATEURS} from "../actions/action-types";

const initialState = [];

export default function UtilisateursReducer(state = initialState, action) {
    switch (action.type) {
        case LISTE_UTILISATEURS:
            let utilisateurs = [];
            action.payload.map(utilisateur => (
                utilisateurs.push({
                    id: utilisateur.id,
                    nom: utilisateur.nom,
                    prenom: utilisateur.prenom,
                    dateDeNaissance: utilisateur.dateDeNaissance,
                    lieuNaissance: utilisateur.lieuNaissance,
                    adresse: utilisateur.adresse
                })
            ));
            return utilisateurs;

        default:
            return state;
    }
};
