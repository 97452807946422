import React, {Component} from 'react';
import {connect} from 'react-redux';
import {listerUtilisateurs} from "../actions/utilisateurs";
import {genererAttestation, resetAttestation, ajouterMetrique} from "../actions/attestation";
import BoutonGenererAttestation from "../components/boutonGenererAttestation";
import GenererAttestation from "../components/genererAttestation";
import Loader from "../components/loader";
import Alertes from "./alertes";

class Home extends Component {
    componentWillMount() {
        this.props.listerUtilisateurs();
    }

    genererAttestation(utilisateur) {
        this.props.genererAttestation(utilisateur);
        this.props.ajouterMetrique(utilisateur);
    }

    deleteUser(user) {
        this.props.deleteUser(user, this.props.history);
    }

    render() {
        return (
            <div class="p-3">
                <Alertes />
                <Loader />
                {
                    this.props.utilisateurs.map(utilisateur => (
                        <div key={utilisateur.id}>
                            <BoutonGenererAttestation key={utilisateur.id}
                                                      utilisateur={utilisateur}
                                                      genererAttestationCallBack={utilisateur => this.genererAttestation(utilisateur)}/><br /><br />
                            {this.props.resetAttestation()}

                        </div>
                    ))
                }
                {
                    this.props.attestation && this.props.attestation.id ?
                        <GenererAttestation utilisateur={this.props.attestation}/>
                        :
                        <div>&nbsp;</div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    listerUtilisateurs,
    genererAttestation,
    ajouterMetrique,
    resetAttestation
};

const mapStateToProps = (state) => {
    return {
        utilisateurs: state.utilisateurs,
        attestation: state.attestation.attestation
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
