import {GENERER_ATTESTATION} from "../actions/action-types";

const initialState = {
    attestation: undefined
};

export default function AttestationReducer(state = initialState, action) {
    switch (action.type) {
        case GENERER_ATTESTATION:
            return {
                ...state,
                attestation: action.payload
            }

        default:
            return state;
    }
};
