import React, {Component} from 'react';
import Attestation from "./attestation";
import html2PDF from 'jspdf-html2canvas';
import {connect} from 'react-redux';
import {ajouterSucces} from "../actions/alertes";

class GenererAttestation extends Component {
    componentDidMount() {
        let page = document.getElementById('attestation');
        html2PDF(page, {
            jsPDF: {
                format: 'a4',
            },
            imageType: 'image/jpeg',
            output: this.nomFichierPdf()
        });
        this.props.ajouterSucces("Attestation générée avec succès.");
    }

    render() {
        return (
            <Attestation utilisateur={this.props.utilisateur}/>
        );
    }

    nomFichierPdf() {
        const maintenant = Date.now();
        return `${maintenant}_${this.props.utilisateur.nom}_${this.props.utilisateur.prenom}.pdf`;
    }
}

const mapDispatchToProps = {
    ajouterSucces
};

const mapStateToProps = (state) => {
    return {

    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GenererAttestation);

