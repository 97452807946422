import axios from 'axios';
import {LISTE_UTILISATEURS} from "./action-types";

export const URL_API_PEOPLE_ATTESTATION = "https://j91mcai87h.execute-api.us-east-1.amazonaws.com/prod";

export function listerUtilisateurs() {
    return function(dispatch) {
        const option = {
            method: "GET",
            url: `${URL_API_PEOPLE_ATTESTATION}/utilisateurs`,
            headers: {
                "Content-Type": "application/json"
            }
        };
        axios(option).then(reponse => {
            dispatch({
                type: LISTE_UTILISATEURS,
                payload: reponse.data
            });
        });
    }
}

