import React, {Component} from 'react';

class Loader extends Component {
    render() {
        return (
            <div class="loader-container">
                <div class="loader">
                </div>
            </div>
        );
    }
}

export default Loader;
