import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ajouterSucces, supprimerSucces} from "../actions/alertes";

class Alertes extends Component {
    componentDidUpdate() {
        if (this.props.succes.message) {
            const interval = setInterval(() => {
                this.props.supprimerSucces();
            }, 5000);
            return () => clearInterval(interval);
        }
    }

    render() {
        return (
            this.props.succes.message ?
                <div class="container">
                    <div className="alert alert-success">
                        <strong>Success!</strong> {this.props.succes.message}
                    </div>
                </div>
                :
                <div>&nbsp;</div>
        );
    }
}

const mapDispatchToProps = {
    ajouterSucces,
    supprimerSucces
};

const mapStateToProps = (state) => {
    return {
        succes: state.succes
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Alertes);
