import React, {Component} from 'react';

class Ressources extends Component {
    render() {
        return (
            <div>
                Voici des ressources
            </div>
        );
    }
}

export default Ressources;