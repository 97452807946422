import React, {Component} from 'react';
import signature from '../images/signature.png';

class Attestation extends Component {
    render() {
        const {nom, prenom, dateDeNaissance, lieuNaissance, adresse} = this.props.utilisateur;
        return (
            <div id="attestation" style={{width: "800px", height: "1100px"}}>
                <br />
                <p style={{fontSize: "26px", fontStyle: "bold"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>ATTESTATION DE DÉPLACEMENT DÉROGATOIRE</b>
                </p>
                <p style={{fontSize: "26px"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>DURANT LES HORAIRES DU COUVRE-FEU</b>
                </p>
                <div style={{marginLeft: "60px", fontSize:"14px"}}>
                    <p style={{fontStyle: "italic"}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        En application de l’article 4 du décret n°2020-1310 du 29 octobre 2020 prescrivant les<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        mesures générales nécessaires pour faire face à l’épidémie de covid-19 dans le cadre de<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        l'état d'urgence sanitaire
                    </p>
                    <p>
                        Je soussigné(e),
                    </p>
                    <p>
                        Mme/M. : {nom} {prenom}<br/>
                        Né(e) le : {dateDeNaissance}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        , à : {lieuNaissance}<br/>
                        Demeurant : {adresse}
                    </p>
                    <p>
                        certifie que mon déplacement est lié au motif suivant (cocher la case) autorisé en application
                        des
                        mesures<br/>
                        générales nécessaires pour faire face à l'épidémie de covid-19 dans le cadre de l'état d'urgence
                        sanitaire :
                    </p>
                    <p style={{fontSize: "10px"}}>
                        Note : les personnes souhaitant bénéficier de l’une de ces exceptions doivent se munir s’il y a
                        lieu, lors de leurs déplacements hors de<br/>
                        leur domicile, d’un document leur permettant de justifier que le déplacement considéré entre
                        dans le champ de l’une de ces exceptions.
                    </p>
                    <br/>
                    <ol>
                        <li>
                            Déplacements entre le domicile et le lieu d’exercice de l’activité professionnelle ou le
                            lieu<br/>
                            d’enseignement et de formation, déplacements professionnels ne pouvant être différés.
                        </li>
                        <li>
                            Déplacements pour des consultations et soins ne pouvant être assurés à distance et<br/>
                            ne pouvant être différés ou pour l’achat de produits de santé.
                        </li>
                        <li>
                            Déplacements pour motif familial impérieux, pour l’assistance aux personnes vulnérables<br/>
                            ou précaires ou pour la garde d’enfants.
                        </li>
                        <li>
                            Déplacements des personnes en situation de handicap et de leur accompagnant.
                        </li>
                        <li>
                            Déplacements pour répondre à une convocation judiciaire ou administrative.
                        </li>
                        <li>
                            Déplacements pour participer à des missions d’intérêt général sur demande de<br/>
                            l’autorité administrative.
                        </li>
                        <li>
                            Déplacements liés à des transits ferroviaires ou aériens pour des déplacements<br/>
                            de longues distances.
                        </li>
                        <li>
                            Déplacements brefs, dans un rayon maximal d’un kilomètre autour du domicile pour<br/>
                            les besoins des animaux de compagnie.
                        </li>
                    </ol>
                    <br />
                    <p>
                        Fait à Bordeaux:
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        , à : {recupererHeure()}
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Pour lutter contre<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        l’épidémie, téléchargez
                    </p>
                    <p>Le : {recupererJour()}</p>
                    <p>(Date et heure de début de sortie à mentionner obligatoirement)</p>
                    <p>Signature : <img src={signature} /></p>
                </div>
            </div>
        );
    }
}

function recupererHeure() {
    var maintenant = new Date();
    var heure   = ('0'+maintenant.getHours()  ).slice(-2);
    var minute  = ('0'+maintenant.getMinutes()).slice(-2);
    var seconde = ('0'+maintenant.getSeconds()).slice(-2);

    return `${heure}:${minute}:${seconde}`
}

function recupererJour() {
    var maintenant = new Date();
    var annee   = maintenant.getFullYear();
    var mois    = ('0'+maintenant.getMonth()+1).slice(-2);
    var jour    = ('0'+maintenant.getDate()   ).slice(-2);

    return `${jour}/${mois}/${annee}`;
}

export default Attestation;
