import {combineReducers} from "redux";
import UtilisateursReducer from "./utilisateurs-reducer";
import AttestationReducer from "./attestation-reducer";
import AlertesReducer from "./alertes-reducer";

const rootReducer = combineReducers({
    utilisateurs: UtilisateursReducer,
    attestation: AttestationReducer,
    succes: AlertesReducer
});

export default rootReducer;
