import React from 'react';

const BoutonGenererAttestation = (props) => {
    const {nom, prenom} = props.utilisateur;
    return (
        <button type="button" className="btn btn-info"
                onClick={() => props.genererAttestationCallBack(props.utilisateur)}>{nom} {prenom}</button>
    )
}

export default BoutonGenererAttestation;
